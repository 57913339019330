<template>
    <b-navbar
        ref="nav"
        toggleable="xl"
        fixed="top"
    >
        <b-row id="fline" class="align-items-center">
            <b-col cols="2">
                <b-button
                    href="/contact"
                    pill
                    variant="outline-secondary"
                >
                    <b-icon-envelope />
                </b-button>
            </b-col>
            <b-col cols="8">
                <b-navbar-brand to="/">
                    <b-img src="../../public/logo.png" fluid/>
                </b-navbar-brand>
            </b-col>
            <b-col cols="2">
                <b-button
                    id="facebook_button"
                    :href="facebook"
                    pill
                    variant="outline-secondary"
                    target="_blank"
                    style="margin-right: 5%;"
                >
                    <b-icon-facebook />
                </b-button>
                <b-button
                    :href="instagram"
                    pill
                    variant="outline-secondary"
                    target="_blank"
                >
                    <b-icon-instagram />
                </b-button>
            </b-col>
        </b-row>
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-collapse id="nav_collapse" is-nav>
            <b-navbar-nav class="d-flex mx-auto">
                <b-nav-item to="/">Accueil</b-nav-item>
                <b-nav-item to="/apropos">À propos de moi</b-nav-item>
                <b-nav-item-dropdown text="Mes prestations">
                    <b-dropdown-item to="/services/thalassobainbebe">
                        Thalasso Bain Bébé©
                    </b-dropdown-item>
                    <b-dropdown-item to="/services/bainenveloppe">
                        Atelier bain enveloppé
                    </b-dropdown-item>
                    <b-dropdown-item to="/services/atelierportage">
                        Atelier portage physiologique
                    </b-dropdown-item>
                    <b-dropdown-item to="/services/atelierreflexologie">
                        Atelier réflexologie bébé<br />émotionnelle®
                    </b-dropdown-item>
                    <b-dropdown-item to="/services/ateliermassage">
                        Atelier massage bébé
                    </b-dropdown-item>
                    <b-dropdown-item to="/services/accompagnementsommeil">
                        Accompagnement au sommeil
                    </b-dropdown-item>
                    <b-dropdown-item to="/services/allaitementmaternel">
                        Allaitement : préparation et<br />accompagnement
                    </b-dropdown-item>
                    <b-dropdown-item to="/services/accompagnementprepostnatal">
                        Accompagnement pré et post natal
                    </b-dropdown-item>
                    <b-dropdown-item to="/services/massagekobido">
                        Le massage liftant japonais<br />
                        du visage inspiré du Kobido
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item to="/packs">Packs</b-nav-item>
                <b-nav-item to="/cartecadeau">Bon cadeau</b-nav-item>
                <b-nav-item to="/contact">Contact</b-nav-item>
                <b-nav-item to="/livredor">Livre d'Or</b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import { mapState } from 'vuex';

import {
    BIcon,
    BIconEnvelope,
    BIconFacebook,
    BIconInstagram,
} from 'bootstrap-vue';

export default {
    name: 'Navbar',
    components: {
        BIcon,
        BIconEnvelope,
        BIconFacebook,
        BIconInstagram,
    },
    data: function() {
        return {
            isSticky: false,
        };
    },
    computed: {
        ...mapState(['name', 'facebook', 'instagram']),
    },
    created: function() {
        window.addEventListener('scroll', this.handleScroll);
    },
    mounted: function() {
        this.$refs.nav.$el.classList.remove('fixed-top');
    },
    destroyed: function() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        /**
         * When user scrolls, make the navbar sticky if possible.
         * We consider it is possible when the difference between available
         * height and components height is positive.
         */
        handleScroll(event) {
            let nav = this.$refs.nav;
            let classList = nav.$el.classList;

            if (window.scrollY > 0 && this.testSticky()) {
                if (!this.isSticky) {
                    this.isSticky = true;
                    classList.add('fixed-top');
                    nav.$el.setAttribute('style', 'opacity: 0.9');
                    this.$emit('set-sticky', { height: nav.$el.clientHeight });
                }
            } else {
                if (this.isSticky) {
                    this.isSticky = false;
                    classList.remove('fixed-top');
                    nav.$el.removeAttribute('style');
                    this.$emit('set-sticky', { height: 0 });
                }
            }
        },
        /**
         * Tests if sum of components height is positive.
         * @return true if so, else false.
         */
        testSticky() {
            let navHeight = this.$refs.nav.$el.clientHeight;
            let viewHeight = document.getElementById('view').clientHeight;
            let footerHeight = document.getElementById('footer').clientHeight;

            let dif =
                navHeight + viewHeight + footerHeight - screen.availHeight;

            return dif > 0;
        },
    },
};
</script>

<style scoped>
nav {
    background-color: var(--light-neutral);
    margin-bottom: 2em;
}

.nav-item {
    margin-right: 25px;
    margin-left: 25px;
}

button:hover {
    color: #fff;
}

@media (max-width: 353px) {
    nav {
        display: block;
        text-align: center;
    }

    #fline {
        margin-bottom: 10%;
    }
}

@media (max-width: 503px) {
    nav a img {
        width: 250px;
    }
}

@media (min-width: 504px) {
    nav a img {
        width: 400px;
    }
}

@media (max-width: 1199px) {
    #facebook_button {
        margin-bottom: 5%;
    }
}

@media (min-width: 1200px) {
    nav {
        display: block;
        text-align: center;
    }

    #fline {
        margin-bottom: 3%;
    }
}
</style>
