<template>
    <b-row id="footer" class="mt-5" align-v="center">
        <b-col xs="12" md="4" class="text-center">
            <span class="name">Léa Noguès</span><br />
            <b-icon-geo-fill /> {{ city1 }} et ses alentours, <br />
            <span v-show="!isOnContact">
                <b-icon-telephone-fill /> {{ mobileNumber }}
            </span>
        </b-col>
        <b-col xs="12" md="4" class="text-center">
            {{ currentYear }} © Tous droits réservés.
        </b-col>
    </b-row>
</template>

<script>
import { mapState } from 'vuex';

import {
    BIcon,
    BIconGeoFill,
    BIconEnvelopeFill,
    BIconTelephoneFill,
    BIconFacebook,
    BIconInstagram,
} from 'bootstrap-vue';

export default {
    name: 'Footer',
    components: {
        BIcon,
        BIconGeoFill,
        BIconEnvelopeFill,
        BIconTelephoneFill,
        BIconFacebook,
        BIconInstagram,
    },
    data: function() {
        return {
            isOnContact: false,
        };
    },
    watch: {
        $route(to, from) {
            this.isOnContact = Boolean(to.name === 'Contact');
            if (this.isOnContact) {
            } else {
            }
        },
    },
    computed: {
        ...mapState([
            'name',
            'city1',
            'mailadd',
            'mobileNumber',
            'facebook',
            'instagram',
        ]),
        currentYear() {
            return new Date().getFullYear();
        },
    },
};
</script>

<style scoped>
.name {
    font-family: 'Montserrat';
    font-size: 20px;
}

.btn svg {
    font-size: 23px;
}

.btn .fa-facebook {
    color: var(--primary);
}

.btn-bg-transparent:hover {
    background-color: var(--primary);
}

.btn-bg-transparent:hover svg {
    color: #fff;
}
</style>
