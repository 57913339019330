import { extend } from 'vee-validate';

import { required, email } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    params: ['fieldName'],
    message: "J'ai besoin de votre {fieldName}.",
});

extend('email', {
    ...email,
    message: "J'ai besoin d'une adresse valide pour vous recontacter.",
});

extend('phone', {
    validate(value) {
        let pattern = new RegExp('^([0-9]{2}[.,-,s]?){4}[0-9]{2}$');

        return pattern.test(value);
    },
    message:
        "J'ai besoin d'un numéro de téléphone valide pour vous recontacter",
});
