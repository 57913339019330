<template>
    <transition name="fade">
        <b-button pill v-show="show" @click="onClick">
            <b-icon-chevron-bar-up />
        </b-button>
    </transition>
</template>

<script>
import { BIcon, BIconChevronBarUp } from 'bootstrap-vue';

export default {
    name: 'UpButton',
    components: { BIcon, BIconChevronBarUp },
    data: function() {
        return {
            show: false,
        };
    },
    created: function() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        onClick() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
        handleScroll(event) {
            if (window.scrollY > 100) {
                this.show = true;
            } else {
                this.show = false;
            }
        },
    },
};
</script>

<style scoped>
button {
    position: fixed;
    right: 3%;
    bottom: 5%;
    opacity: 0.6;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
