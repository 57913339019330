<template>
    <b-container @contextmenu.prevent="voidFunction" fluid>
        <b-row class="text-center">
            <b-col cols="12">
                <b-img src="@/assets/home.png" fluid-grow/>
            </b-col>
        </b-row>
        <b-row class="text-center mb-3 mt-5 mr-1 ml-1" align-v="center">
            <b-col cols="12" md="6">
                <h1>Bienvenue</h1><br />
                <p>
                    sur le site de <span id="name">Ô Rythme de Bébé</span>,
                    infirmière puéricultrice et accompagnante périnatale.<br />
                    Il a pour but d'accompagner<br />
                    les (futurs) parents et leurs bébés dans les meilleures
                    conditions possibles et pour cela je vous propose :<br />
                    <br />
                    Un accompagnement à votre domicile,<br />
                    Un suivi personnalisé adapté à votre enfant et votre
                    famille,<br />
                    Une écoute bienveillante,<br />
                    Mes compétences à votre service.<br />
                    <br />
                    Vous trouverez sur le site toutes les informations sur les
                    prestations proposées.<br />
                    Pour plus de renseignements, n'hésitez pas à me
                    <b-link to="/contact"><em>contacter</em></b-link>.<br />
                    <br />
                    Bonne visite et à bientôt j'espère.
                    <br class="d-md-none" /><br class="d-md-none"/>
                </p>
            </b-col>
            <b-col cols="6" offset="3" md="4" offset-md="1">
                <b-img src="@/assets/colored_illustration.png" fluid/>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'Home',
    components: { NonClickableImg },
};
</script>

<style scoped>
#name {
    font-family: 'Conquette';
    font-size: 1.25em;
    margin-left: 2px;
}

.container-fluid {
    padding-left: 0px;
    padding-right: 0px;

    margin-top: -2em;
}
</style>
