import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Accueil',
        component: Home,
    },
    {
        path: '/apropos',
        name: 'À Propos',
        component: () => import('../views/About.vue'),
    },
    {
        path: '/services/thalassobainbebe',
        name: 'Thalasso Bain Bébé',
        component: () => import('../views/BabyBathThalasso.vue'),
    },
    {
        path: '/services/accompagnementprepostnatal',
        name: 'Accompagnement pré et post natal',
        component: () => import('../views/PrePostNatalSupport.vue'),
    },
    {
        path: '/services/bainenveloppe',
        name: 'Bain Enveloppé',
        component: () => import('../views/WrappedBath.vue'),
    },
    {
        path: '/services/atelierportage',
        name: 'Atelier Portage',
        component: () => import('../views/BabyWearing.vue'),
    },
    {
        path: '/services/atelierreflexologie',
        name: 'Atelier Reflexologie',
        component: () => import('../views/Reflexology.vue'),
    },
    {
        path: '/services/ateliermassage',
        name: 'Atelier Massage',
        component: () => import('../views/Massage.vue'),
    },
        {
        path: '/services/accompagnementsommeil',
        name: 'Accompagnement au Sommeil',
        component: () => import('../views/Sleep.vue'),
    },
    {
        path: '/services/allaitementmaternel',
        name: 'Allaitement Maternel',
        component: () => import('../views/Feeding.vue'),
    },
    {
        path: '/services/massagekobido',
        name: 'Le massage liftant japonais du visage inspiré du Kobido',
        component: () => import('../views/Kobido.vue'),
    },
    {
        path: '/packs',
        name: 'Packs',
        component: () => import('../views/Packs.vue'),
    },
    {
        path: '/cartecadeau',
        name: 'Carte Cadeau',
        component: () => import('../views/GiftCard.vue'),
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue'),
    },
    {
        path: '/livredor',
        name: 'Livre d\'Or',
        component: () => import('../views/GoldenBook.vue'),
    },
    {
        path: '*',
        name: '404NotFound',
        component: () => import('../views/404NotFound.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

export default router;
