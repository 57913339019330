import Vue from 'vue';

import router from './router';
import store from './store';

import BootstrapVue from 'bootstrap-vue';
import * as VeeValidate from 'vee-validate';

import App from './App.vue';

import '../app.scss';

import './formValidators';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VeeValidate);

Vue.mixin({
    methods: {
        voidFunction() {},
    },
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
