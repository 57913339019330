import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        name: 'Ô Rythme de Bébé',
        mailadd: 'lea.nogues@orythmedebebe.fr',
        donotanswmail: 'website@orythmedebebe.fr',
        mobileNumber: '06 29 28 83 41',
        city1: 'Toulouse',
        facebook: 'https://facebook.com/orythmedebebe/',
        instagram: 'https://instagram.com/o.rythme.de.bebe',
        domainname: 'orythmedebebe.fr',
        protocol: 'https://',
    },
});
