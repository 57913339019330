<template>
    <div id="app">
        <div ref="compensation" />
        <navbar @set-sticky="compensateNav" />
        <router-view id="view" />
        <Footer />
        <up-button />
    </div>
</template>

<script>
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import UpButton from './components/UpButton';

export default {
    components: {
        Navbar,
        Footer,
        UpButton,
    },
    data: function() {
        return {
            showDismissable: true,
        };
    },
    methods: {
        compensateNav(payload) {
            let block = this.$refs.compensation;
            block.setAttribute('style', 'height: ' + payload.height + 'px;');
        },
    },
};
</script>

<style>
#app {
    background-color: #fff;
    font-family: 'Montserrat', Times, serif;
}

h1,
h2,
h3,
h4 {
    font-family: 'Conquette';
    color: var(--primary);
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
    background-color: var(--light-neutral);
}

::-webkit-scrollbar:horizontal {
    display: none;
}
</style>
