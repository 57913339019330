<template>
    <b-container @contextmenu.prevent="voidFunction" class="mt-2">
        <b-img
            :src="require(`@/assets/${url}`)"
            :height="maxheight"
            :fluid="fluid"
        />
    </b-container>
</template>

<script>
export default {
    name: 'NonClickableImg',
    props: {
        url: {
            type: String,
            required: true,
        },
        maxheight: {
            default: 'auto',
        },
        maxwidth: {
            default: 'auto',
        },
    },
    computed: {
        fluid() {
            return !(this.maxheight != 'auto' || this.maxwidth != 'auto');
        },
    },
};
</script>
